import {createRouter, createWebHistory} from 'vue-router';

const {BASE_URL, NODE_ENV} = process.env;
const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
		props: true,
	},
    {
        path: '/resume',
        name: 'Resume',
        component: () => {
            if (NODE_ENV !== 'development') return import(/* webpackChunkName: "underConstruction" */ '../views/UnderConstruction.vue');
            return import(/* webpackChunkName: "resume" */ '../views/Resume.vue');

        },
        props: true,
    },
];

const router = createRouter({
    history: createWebHistory(BASE_URL),
	routes,
});

export default router;
