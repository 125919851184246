<template>
  <router-view :is-dark-mode="isDarkMode" :toggle-dark-mode="toggleDarkMode" />
</template>

<script>
  export default {
    data() {
      return {
        isDarkMode: (this.isSystemThemeDark() && this.getBrowserTheme() !== 'light')
            || (!this.isSystemThemeDark() && this.getBrowserTheme() === null),
        isWebFontLoaded: false
      }
    },
    beforeMount() {
      const fonts = Array.from(new Set(document.fonts.values().map((font) => font.family.toLowerCase())));
      this.isWebFontLoaded = fonts.includes('noto sans');
    },
    mounted() {
      this.updateThemeSettings(this.isDarkMode);
    },
    watch: {
      isDarkMode(isEnabled) {
        this.updateThemeSettings(isEnabled);
      },
      isWebFontLoaded(isLoaded) {
        if (isLoaded) {
          document.getElementsByTagName('body')[0].classList.add('wf-loaded');
        } else {
          document.getElementsByTagName('body')[0].classList.remove('wf-loaded');
        }
      }
    },
    methods: {
      isSystemThemeDark() {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      },
      getBrowserTheme() {
        return localStorage.getItem('prnv-theme');
      },
      updateThemeSettings(isDarkTheme) {
        if (isDarkTheme) {
          localStorage.removeItem('prnv-theme');
          document.getElementsByTagName('html')[0].classList.remove('theme-light');
        } else {
          localStorage.setItem('prnv-theme', 'light');
          document.getElementsByTagName('html')[0].classList.add('theme-light');
        }
      },
      toggleDarkMode() {
        this.isDarkMode = !this.isDarkMode;
      },
    }
  }
</script>

<style lang="scss">
  @import './scss/global.scss';

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .btn-link {
      text-decoration: none;
    }
  }
</style>
